import EntryProxy from '@/app/proxies/EntryProxy';

import IbanProxy from '@/app/proxies/IbanProxy';
import MailDomainProxy from '@/app/proxies/MailDomainProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            tickets: [],
            id: null,
            formVersion: 'A'
        }
    },

    getters: {
        all: state => state.entry,
        getNumbers: state =>
        {
            if( state.entry.tickets )
            {
                return state.entry.tickets;
            }

            return [];
        }
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        setNumbers: ( state, payload ) =>
        {
            state.entry['tickets'] = payload;
        },

        setActiecode: ( state, payload ) =>
        {
            state.entry['code'] = payload;
        }
    },

    actions: {
        setNumbers: ( context, payload ) =>
        {
            let numbers = [];
            for( let ticket of payload )
            {
                let processedNumbers = [];
                ticket.numbers.forEach( number => processedNumbers.push( number.toString().padStart( 2, '0' ) ) );
                numbers.push( processedNumbers );
            }

            context.commit( 'setNumbers', numbers );
        },

        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkCode: ( context, payload ) =>
        {
            let codes = [
                'LJB', 'LMP', 'LT', 'LVD', 'LVV', 'LNG', 'LP', 'LM', 'LLP', 'LCR', 'LCT', 'LCD', 'LA', 'HH', 'HA', 'LF'
            ];

            let found = codes.find( item =>
            {
                return payload.indexOf( item ) === 0;
            });

            if( found )
            {
                context.commit( 'setActiecode', payload );
                return Promise.resolve({ correct: true });
            }
            else
            {
                return Promise.resolve({ correct: false });
            }
        },

        checkIban: ( context, payload ) =>
        {
            // payload = { iban: <iban> }

            return new IbanProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkMailDomain: ( context, payload ) =>
        {
            // payload = { emailaddress: <emailaddress> }

            return new MailDomainProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return error;
                    // return Promise.reject( error );
                });
        }
    }
};
