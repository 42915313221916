<template>
    <div class="main-layout">
        <div class="top-wrapper">
            <div class="lotto-logo">
                <a href="https://lotto.nederlandseloterij.nl/" target="_blank">
                    <img src="/static/img/logos/lotto.png" />
                    <!-- p>
                        <svg class="arrow">
                            <use xlink:href="#icon-arrow-right" href="#icon-arrow-right"></use>
                        </svg>
                        terug naar lotto.nl
                    </p -->
                </a>
            </div>

        </div>

        <!-- v-ballenregen v-if="isRegenRoute"></v-ballenregen -->

        <div class="content">
            <slot></slot>
        </div>

        <footer>
            <!-- <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <p class="disclaimer small">Voor het organiseren van Lotto is aan Lotto bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" :href="'/static/pdf/actievoorwaarden.pdf'" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div> -->
            <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <img class="big" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer">Voor het organiseren van Lotto is aan Lotto B.V. vergunning verleend door de Kansspelautoriteit, kenmerk 10510 d.d. 23 december 2016. Trekking Laan van Hoornwijck 55 te Rijswijk.<br class="hide-for-small"/>&nbsp;Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy&nbsp;Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    // import Ballenregen from '@/app/components/Ballenregen';
    import { tracking } from '@/app/mixins';

    export default {
        components: {
            // 'v-ballenregen': Ballenregen
        },

        mixins: [ tracking ],

        data()
        {
            return {
                showRegenAt: [ 'Landing', 'Confirmed' ]
            };
        },

        computed: {
            isRegenRoute()
            {
                return this.showRegenAt.indexOf( this.$route.name ) > -1;
            }
        },

        methods: {
            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + '/static/pdf/actievoorwaarden.pdf' );
            },

            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            }
        }
    };
</script>
